var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"haciendaValidation"},[_c('b-row',{staticClass:"p-md-5 justify-content-center"},[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Usuario","label-for":"hacienda-username"}},[_c('validation-provider',{attrs:{"name":"Usuario","rules":("" + (_vm.required ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-username","name":"hacienda-username"},model:{value:(_vm.hacienda.username),callback:function ($$v) {_vm.$set(_vm.hacienda, "username", $$v)},expression:"hacienda.username"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Clave","label-for":"hacienda-password"}},[_c('validation-provider',{attrs:{"name":"Clave","rules":("" + (_vm.required ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-password","name":"hacienda-password","type":"password"},model:{value:(_vm.hacienda.password),callback:function ($$v) {_vm.$set(_vm.hacienda, "password", $$v)},expression:"hacienda.password"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"PIN llave criptografica","label-for":"hacienda-pin"}},[_c('validation-provider',{attrs:{"name":"PIN llave criptografica","rules":("" + (_vm.required ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-pin","name":"hacienda-pin","type":"password"},model:{value:(_vm.hacienda.pin),callback:function ($$v) {_vm.$set(_vm.hacienda, "pin", $$v)},expression:"hacienda.pin"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Archivo llave criptografica","label-for":"hacienda-key"}},[_c('validation-provider',{attrs:{"name":"Archivo llave criptografica"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-file',{ref:"hacienda-key",attrs:{"name":"hacienda-key","browse-text":"Examinar","rules":("" + (_vm.required ? 'required' : ''))},on:{"change":function($event){return _vm.onChangeFile($event)}},model:{value:(_vm.hacienda.key),callback:function ($$v) {_vm.$set(_vm.hacienda, "key", $$v)},expression:"hacienda.key"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Consecutivo actual Facturas (FE)","label-for":"hacienda-cfe"}},[_c('validation-provider',{attrs:{"name":"Consecutivo actual Facturas (FE)","rules":("" + (_vm.required ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-cfe","name":"hacienda-cfe"},model:{value:(_vm.hacienda.cfe),callback:function ($$v) {_vm.$set(_vm.hacienda, "cfe", $$v)},expression:"hacienda.cfe"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Consecutivo actual Tiquetes (TE)","label-for":"hacienda-cte"}},[_c('validation-provider',{attrs:{"name":"Consecutivo actual Tiquetes (TE)","rules":("" + (_vm.required ? 'required|integer' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-cte","name":"hacienda-cte"},model:{value:(_vm.hacienda.cte),callback:function ($$v) {_vm.$set(_vm.hacienda, "cte", $$v)},expression:"hacienda.cte"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Consecutivo actual Notas de credio (NC)","label-for":"hacienda-cnc"}},[_c('validation-provider',{attrs:{"name":"Consecutivo actual Notas de credio (NC)","rules":("" + (_vm.required ? 'required|integer' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-cnc","name":"hacienda-cnc"},model:{value:(_vm.hacienda.cnc),callback:function ($$v) {_vm.$set(_vm.hacienda, "cnc", $$v)},expression:"hacienda.cnc"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Consecutivo actual Notas de debito (ND)","label-for":"hacienda-cnd"}},[_c('validation-provider',{attrs:{"name":"Consecutivo actual Notas de debito (ND)","rules":("" + (_vm.required ? 'required|integer' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-cnd","name":"hacienda-cnd"},model:{value:(_vm.hacienda.cnd),callback:function ($$v) {_vm.$set(_vm.hacienda, "cnd", $$v)},expression:"hacienda.cnd"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Consecutivo actual recepción Documentos aceptados (CCE)","label-for":"hacienda-ccce"}},[_c('validation-provider',{attrs:{"name":"Consecutivo actual recepción Documentos aceptados (CCE)","rules":("" + (_vm.required ? 'required|integer' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-ccce","name":"hacienda-ccce"},model:{value:(_vm.hacienda.ccce),callback:function ($$v) {_vm.$set(_vm.hacienda, "ccce", $$v)},expression:"hacienda.ccce"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Consecutivo actual recepción Documentos aceptados parcialmente (CPCE)","label-for":"hacienda-ccpce"}},[_c('validation-provider',{attrs:{"name":"Consecutivo actual recepción Documentos aceptados parcialmente (CPCE)","rules":("" + (_vm.required ? 'required|integer' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-ccpce","name":"hacienda-ccpce"},model:{value:(_vm.hacienda.ccpce),callback:function ($$v) {_vm.$set(_vm.hacienda, "ccpce", $$v)},expression:"hacienda.ccpce"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Consecutivo actual recepción Documentos rechazados (RCE)","label-for":"hacienda-crce"}},[_c('validation-provider',{attrs:{"name":"Consecutivo actual recepción Documentos rechazados (RCE)","rules":("" + (_vm.required ? 'required|integer' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-crce","name":"hacienda-crce"},model:{value:(_vm.hacienda.crce),callback:function ($$v) {_vm.$set(_vm.hacienda, "crce", $$v)},expression:"hacienda.crce"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Consecutivo actual facturas de compra (Simplificado FEC)","label-for":"hacienda-cfec"}},[_c('validation-provider',{attrs:{"name":"Consecutivo actual facturas de compra (Simplificado FEC)","rules":("" + (_vm.required ? 'required|integer' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"hacienda-cfec","name":"hacienda-cfec"},model:{value:(_vm.hacienda.cfec),callback:function ($$v) {_vm.$set(_vm.hacienda, "cfec", $$v)},expression:"hacienda.cfec"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"p-md-5 justify-content-center"},[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('validation-observer',{ref:"emailDataValidation"},[_c('b-form-group',{staticClass:"text-center",attrs:{"label-for":"customEmailConfigCb"}},[_c('div',{staticClass:"d-flex "},[_c('label',{staticClass:"mr-2"},[_vm._v("¿Desea usar una configuración de correo electrónico personalizada?")]),_c('validation-provider',{attrs:{"name":"¿Desea usar una configuración de correo electrónico personalizada?"}},[_c('b-form-checkbox',{attrs:{"id":"customEmailConfigCb","name":"customEmailConfigCb"},model:{value:(_vm.customEmailConfigCb),callback:function ($$v) {_vm.customEmailConfigCb=$$v},expression:"customEmailConfigCb"}})],1)],1)]),_c('b-form-group',{class:!_vm.customEmailConfigCb ? 'd-none' : '',attrs:{"label":"Nombre de usuario","label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":"Nombre de usuario","rules":("" + (!_vm.customEmailConfigCb ? '' : 'required|email'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"emailData-username","name":"emailData-username"},model:{value:(_vm.emailData.username),callback:function ($$v) {_vm.$set(_vm.emailData, "username", $$v)},expression:"emailData.username"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{class:!_vm.customEmailConfigCb ? 'd-none' : '',attrs:{"label":"Contraseña","label-for":"register-password"}},[_c('validation-provider',{attrs:{"name":"Contraseña","rules":("" + (!_vm.customEmailConfigCb ? '' : 'required'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"emailData-password","name":"emailData-password","type":"password"},model:{value:(_vm.emailData.password),callback:function ($$v) {_vm.$set(_vm.emailData, "password", $$v)},expression:"emailData.password"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{class:!_vm.customEmailConfigCb ? 'd-none' : '',attrs:{"label":"Correo emisor (From)","label-for":"register-fromEmail"}},[_c('validation-provider',{attrs:{"name":"Correo emisor (From)","rules":("" + (!_vm.customEmailConfigCb ? '' : 'required|email'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"emailData-fromEmail","name":"emailData-fromEmail"},model:{value:(_vm.emailData.fromEmail),callback:function ($$v) {_vm.$set(_vm.emailData, "fromEmail", $$v)},expression:"emailData.fromEmail"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{class:!_vm.customEmailConfigCb ? 'd-none' : '',attrs:{"label":"Nombre emisor (From)","label-for":"register-fromName"}},[_c('validation-provider',{attrs:{"name":"Nombre emisor (From)","rules":("" + (!_vm.customEmailConfigCb ? '' : 'required'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"emailData-fromName","name":"emailData-fromName"},model:{value:(_vm.emailData.fromName),callback:function ($$v) {_vm.$set(_vm.emailData, "fromName", $$v)},expression:"emailData.fromName"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{class:!_vm.customEmailConfigCb ? 'd-none' : '',attrs:{"label":"Host","label-for":"registrar-host"}},[_c('validation-provider',{attrs:{"name":"Host","rules":("" + (!_vm.customEmailConfigCb ? '' : 'required'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"emailData-host","name":"emailData-host"},model:{value:(_vm.emailData.host),callback:function ($$v) {_vm.$set(_vm.emailData, "host", $$v)},expression:"emailData.host"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{class:!_vm.customEmailConfigCb ? 'd-none' : '',attrs:{"label":"Puerto","label-for":"registrar-puerto"}},[_c('validation-provider',{attrs:{"name":"Puerto","rules":("" + (!_vm.customEmailConfigCb ? '' : 'required'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"emailData-port","name":"emailData-port"},model:{value:(_vm.emailData.port),callback:function ($$v) {_vm.$set(_vm.emailData, "port", $$v)},expression:"emailData.port"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{class:!_vm.customEmailConfigCb ? 'd-none' : '',attrs:{"label":"Protocolo","label-for":"registrar-protocol"}},[_c('validation-provider',{attrs:{"name":"Protocolo","rules":("" + (!_vm.customEmailConfigCb ? '' : 'required'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"emailData-protocol","name":"emailData-protocol"},model:{value:(_vm.emailData.protocol),callback:function ($$v) {_vm.$set(_vm.emailData, "protocol", $$v)},expression:"emailData.protocol"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{class:!_vm.customEmailConfigCb ? 'd-none' : '',attrs:{"label":"Encriptación","label-for":"registrar-encryption"}},[_c('validation-provider',{attrs:{"name":"Encriptación","rules":("" + (!_vm.customEmailConfigCb ? '' : 'required'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"emailData-encryption","name":"emailData-encryption"},model:{value:(_vm.emailData.encryption),callback:function ($$v) {_vm.$set(_vm.emailData, "encryption", $$v)},expression:"emailData.encryption"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
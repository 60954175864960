var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"p-md-5 justify-content-center"},[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('validation-observer',{ref:"userValidation"},[_c('b-form-group',{attrs:{"label":"Nombre y apellido","label-for":"user-name"}},[_c('validation-provider',{attrs:{"name":"Nombre y apellido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"user-name","name":"user-name"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"user-email","name":"user-email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Contraseña","label-for":"login-password"}},[_c('validation-provider',{attrs:{"name":"Contraseña","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"user-password","name":"user-password","type":"password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"p-md-5 justify-content-center"},[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('validation-observer',{ref:"userValidation"},[_c('b-form-group',{attrs:{"label":"¿Desea agregar usuario existente?","label-for":"existingUserCb"}},[_c('validation-provider',{attrs:{"name":"¿Desea agregar usuario existente?"}},[_c('b-form-checkbox',{attrs:{"id":"existingUserCb","name":"existingUserCb"},model:{value:(_vm.existingUserCb),callback:function ($$v) {_vm.existingUserCb=$$v},expression:"existingUserCb"}})],1)],1),_c('b-form-group',{class:!_vm.existingUserCb ? 'd-none' : '',attrs:{"label":"Usuario","label-for":"user-id"}},[_c('validation-provider',{attrs:{"name":"Usuario","rules":("" + (!_vm.existingUserCb ? '' : 'required'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('v-select',{attrs:{"id":"user-id","options":_vm.users,"reduce":function (user) { return user.id; },"label":"name","searchable":""},model:{value:(_vm.user.id),callback:function ($$v) {_vm.$set(_vm.user, "id", $$v)},expression:"user.id"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" No se encontraron usuarios ")])]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(!_vm.existingUserCb)?_c('b-form-group',{attrs:{"label":"Nombre y apellido","label-for":"user-name"}},[_c('validation-provider',{attrs:{"name":"Nombre y apellido","rules":("" + (_vm.existingUserCb ? '' : 'required'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"user-name","name":"user-name"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,3292656752)})],1):_vm._e(),(!_vm.existingUserCb)?_c('b-form-group',{attrs:{"label":"Email","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":("" + (_vm.existingUserCb ? '' : 'required|email'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"user-email","name":"user-email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,3840172379)})],1):_vm._e(),(!_vm.existingUserCb)?_c('b-form-group',{attrs:{"label":"Contraseña","label-for":"login-password"}},[_c('validation-provider',{attrs:{"name":"Contraseña","rules":("" + (_vm.existingUserCb ? '' : 'required'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"user-password","name":"user-password","type":"password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,1490300537)})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
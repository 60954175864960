var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"companyValidation"},[_c('b-row',{staticClass:"p-md-5 justify-content-center"},[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"company-name"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"company-name","name":"company-name"},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nombre fantasia","label-for":"company-fantasy-name"}},[_c('validation-provider',{attrs:{"name":"Nombre fantasia"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"company-fantasy-name","name":"company-fantasy-name"},model:{value:(_vm.company.fantasy_name),callback:function ($$v) {_vm.$set(_vm.company, "fantasy_name", $$v)},expression:"company.fantasy_name"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tipo identificación","label-for":"company-identification-type"}},[_c('validation-provider',{attrs:{"name":"Tipo identificación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-select',{attrs:{"id":"company-identification-type","options":_vm.identificationTypes},model:{value:(_vm.company.identification_type),callback:function ($$v) {_vm.$set(_vm.company, "identification_type", $$v)},expression:"company.identification_type"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Identificación","label-for":"company-identification"}},[_c('validation-provider',{attrs:{"name":"Identificación","rules":_vm.validateIdentificationLength(_vm.company.identification_type)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"company-identification","name":"company-identification"},model:{value:(_vm.company.identification),callback:function ($$v) {_vm.$set(_vm.company, "identification", $$v)},expression:"company.identification"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"company-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"company-email","name":"company-email"},model:{value:(_vm.company.email),callback:function ($$v) {_vm.$set(_vm.company, "email", $$v)},expression:"company.email"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Teléfono","label-for":"company-phone"}},[_c('validation-provider',{attrs:{"name":"Teléfono","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"company-phone","name":"company-phone"},model:{value:(_vm.company.phone),callback:function ($$v) {_vm.$set(_vm.company, "phone", $$v)},expression:"company.phone"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Provincia","label-for":"company-province"}},[_c('validation-provider',{attrs:{"name":"Provincia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('v-select',{attrs:{"id":"company-province","options":_vm.provinces,"reduce":function (province) { return province.id; },"label":"name","searchable":""},on:{"input":function($event){return _vm.onChangeProvince($event)}},model:{value:(_vm.company.province),callback:function ($$v) {_vm.$set(_vm.company, "province", $$v)},expression:"company.province"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" No se encontraron provincias ")])]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Cantón","label-for":"company-canton"}},[_c('validation-provider',{attrs:{"name":"Cantón","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('v-select',{attrs:{"id":"company-canton","options":_vm.cantons,"reduce":function (canton) { return canton.number; },"label":"name","searchable":""},on:{"input":function($event){return _vm.onChangeCanton($event)}},model:{value:(_vm.company.canton),callback:function ($$v) {_vm.$set(_vm.company, "canton", $$v)},expression:"company.canton"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" No se encontraron cantones ")])]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Distrito","label-for":"company-district"}},[_c('validation-provider',{attrs:{"name":"Distrito","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('v-select',{attrs:{"id":"company-district","options":_vm.districts,"reduce":function (district) { return district.number; },"label":"name","searchable":""},on:{"input":function($event){return _vm.onChangeDistrict($event)}},model:{value:(_vm.company.district),callback:function ($$v) {_vm.$set(_vm.company, "district", $$v)},expression:"company.district"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" No se encontraron distritos ")])]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Barrio","label-for":"company-barrio"}},[_c('validation-provider',{attrs:{"name":"Barrio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('v-select',{attrs:{"id":"company-barrio","options":_vm.barrios,"reduce":function (barrio) { return barrio.id; },"label":"name","searchable":""},model:{value:(_vm.company.barrio),callback:function ($$v) {_vm.$set(_vm.company, "barrio", $$v)},expression:"company.barrio"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" No se encontraron barrios ")])]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Otras Señas","label-for":"company-other-signs"}},[_c('validation-provider',{attrs:{"name":"Otras Señas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-input',{attrs:{"id":"company-other-signs","name":"company-other-signs"},model:{value:(_vm.company.other_signs),callback:function ($$v) {_vm.$set(_vm.company, "other_signs", $$v)},expression:"company.other_signs"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Actividad Económica","label-for":"company-economic_activity"}},[_c('validation-provider',{attrs:{"name":"Actividad Económica","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('v-select',{attrs:{"id":"company-economic_activity","options":_vm.economic_activities,"reduce":function (economic_activity) { return economic_activity.id; },"label":"name","searchable":""},model:{value:(_vm.company.economic_activity),callback:function ($$v) {_vm.$set(_vm.company, "economic_activity", $$v)},expression:"company.economic_activity"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" No se encontraron actividades económicas ")])]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }